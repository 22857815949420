import React, {Component} from 'react'
import styled from 'styled-components'
import {Flex, Box} from '../../styles/grid'
import theme from '../../styles/theme'
import Table from 'react-bootstrap/Table'
class AccountEvents extends Component {
  state = {

  }

  render() {
    const {golfer, history} = this.props

    return (
      <Teams>
        <Box>
          <h2>My Teams</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th></th>
                <th>Team Name</th>
                <th>Roster</th>
                <th>Record</th>
              </tr>
            </thead>
            <tbody>
              {golfer.events.length && golfer.events.map((event, i) => (
                <tr key={i}>
                  <td></td>
                  <td>{event.name}</td>
                  <td>{event.start_date}</td>
                  <td></td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <Flex justifyContent={'center'} p={[20]} backgroundColor={'#f4f4f4'} flexWrap={'wrap'} width={'100%'}>
            <FindEvents onClick={() => history.push('/events')}>
              Find Events
            </FindEvents>
          </Flex>
        </Box>
      </Teams>
    )
  }
}

const FindEvents = styled.button`
  font-size: 16px;
  font-weight: bolder;
  color: #fff;
  width: 200px;
  height: 50px;
  background-color: ${theme.colors.purple};
  border-radius: 4px;
`

const Teams = styled.div`
  width: 100%;
`

export default AccountEvents
