import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';
import mapTheme from '../../styles/mapTheme'
import {Box} from '../../styles/grid'
// import markerIcon from '../../images/dispensary-marker.svg';

const GOOGLE_MAPS_API_KEY = 'AIzaSyA-ky5Uwq4Lc8k8gokVhtDIAD6XiqMwe5k'

export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {}
  }
  onMarkerClick = (dispensary) => {
    this.setState({
      selectedPlace: dispensary,
      showingInfoWindow: true
    });
    this.props.setActiveDispensary(dispensary)

  }

  render() {
    const {event} = this.props;
    console.log(this.props)
    return (
      <GoogleMapReact
        options={{
          styles: mapTheme,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false
        }}
        center={{
          lat: event.latitude || 41.8781,
          lng: event.longitude || -87.6298
        }}
        defaultZoom={14}
        bootstrapURLKeys={{
          key: GOOGLE_MAPS_API_KEY
        }}>
      </GoogleMapReact>
    )
  }
}

export default MapContainer;
