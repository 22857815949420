import React, {Component} from 'react'
import styled from 'styled-components'
import {Flex, Box} from '../../styles/grid'
import theme from '../../styles/theme'
import Table from 'react-bootstrap/Table'
class AccountTeams extends Component {
  state = {
    newTeamName: '',
    teammateEmail: ''
  }
  _changeTeamName = (newTeamName) => {
    this.setState({newTeamName})
  }

  _createTeam = () => {
    const {createTeam, golfer, fetchGolfer} = this.props;
    createTeam({
      name: this.state.newTeamName,
      roster: {
        player1: golfer.email,
        player2: this.state.teammateEmail
      }
    }).then((response) => {
      if (response) {
        fetchGolfer(golfer.id)
      }
    })
  }

  _changeTeammateEmail = (teammateEmail) => {
    this.setState({teammateEmail})
  }

  render() {
    const {golfer} = this.props

    return (
      <Teams>
        <Box>
          <h2>My Teams</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th></th>
                <th>Team Name</th>
                <th>Roster</th>
                <th>Record</th>
              </tr>
            </thead>
            <tbody>
              {golfer.teams.length ? golfer.teams.map((team, i) => (
                <tr key={i}>
                  <td></td>
                  <td>{team.name}</td>
                  <td>{team.roster.player1}, {team.roster.player2}</td>
                  <td></td>
                </tr>
              )) :
                <tr>
                  <td></td>
                  <td>
                    This could be your team. Create one below.
                  </td>
                  <td>
                    Tiger Woods & &nbsp;
                    {golfer.first_name} {golfer.last_name}
                  </td>
                  <td>
                    100-0
                  </td>
                </tr>
              }
            </tbody>
          </Table>
          <Flex p={[20]} backgroundColor={'#f4f4f4'} flexWrap={'wrap'} width={'100%'}>
            <NewTeamLabel>Make a New Team</NewTeamLabel>
            <Input value={this.state.newTeamName} name="name" onChange={(e) => this._changeTeamName(e.target.value)} placeholder={'Team Name'} type='text' />
            <Input value={this.state.teammateEmail} type={'email'} onChange={(e) => this._changeTeammateEmail(e.target.value)} placeholder={"Teammate's email"} />
            <CreateTeamButton onClick={this._createTeam}>Create</CreateTeamButton>

          </Flex>
        </Box>
      </Teams>
    )
  }
}

const NewTeamLabel = styled.label`
  font-size: 16px;
  font-weight: bolder;
  color: ${theme.colors.darkGray};
  width: 100%;
`

const CreateTeamButton = styled.button`
  background-color: ${theme.colors.purple};
  border-radius: none;
  color: #fff;
  width: 20%;
`

const Teams = styled.div`
  width: 100%;
`

const Input = styled.input`
  height: 40px;
  padding-left: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 40%;
`

export default AccountTeams
