import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux'
import {login} from '../../actions/auth'
import styled from 'styled-components'
import theme from '../../styles/theme'

class LoginForm extends Component {
  state = {
    email: '',
    password: ''
  }

  _changeEmail = (email) => {
    this.setState({email})
  }

  _changePassword = (password) => {
    this.setState({password})
  }

  _login = () => {
    const {history, login} = this.props;
    const {email, password} = this.state;

    login({email, password}).then(response => {
      if (response) {
        history.push('/account')
      }
    })
  }
  render() {
    return (
      <FormContainer>
        <FormHeader>
          Log In
        </FormHeader>
        <Inputs>
          <input onChange={(e) => this._changeEmail(e.target.value)} placeholder='email' type='email' />
          <input onChange={(e) => this._changePassword(e.target.value)} placeholder='password' type='password' />
          <SubmitButton onClick={this._login}>Login</SubmitButton>
        </Inputs>
        <RegisterLink>Don't have an account? <button onClick={() => this.props.showForm('register')}>Make one.</button></RegisterLink>
      </FormContainer>
    )
  }
}

const SubmitButton = styled.button`
  height: 75px;
  background: ${theme.colors.purple};
  color: #fff;
  font-size: 18px;
  font-weight: bolder;
  width: 100%;
`

const RegisterLink = styled.span`
  display: block;
  margin-top: 30px;
  font-size: 16px;
  button {
    font-size: 16px;
    border: none;
    background: none;
    appearance: none;
    -webkit-appearance: none;
    color: ${theme.colors.blue};
  }
`

const FormHeader = styled.h3`
  font-size: 25px;
  margin: 20px 0;
`

const Inputs = styled.div`
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  input {
    padding-left: 17px;
    font-size: 22px;
    height: 75px;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
  }
`

const FormContainer = styled.div`
  width: 400px;
`

const InputPlacer = styled.div`

`

export default connect(
  state => ({}),
  dispatch => ({
    login: bindActionCreators(login, dispatch)
  })
) (LoginForm)
