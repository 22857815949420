import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux'
import {error, success} from '../../actions/notifications'
import {login} from '../../actions/auth'
import styled from 'styled-components'
import LoginForm from './login-form'
import Registration from './register-form'
import Alert from '../../components/common/Alert'
import queryString from 'query-string'

class Auth extends Component {
  state = {
    showForm: 'login',
    byInvite: false
  }

  componentDidMount() {
    const {match, notifySuccess, history, login} = this.props;
    let showForm = 'login'
    let parsedParams = queryString.parse(history.location.search)

    if (match.url === '/register') {
      showForm = 'register'
    }

    if (parsedParams.frodo) {
      let pw = parsedParams.frodo
      let email = parsedParams.golfer.replace(' ', '+')

      login({email: email, password: pw}).then((response) => {
        history.push('/account/teams')
        notifySuccess('Welcome to King of the Tee, accept your team invite here and then finish setting up your profile!')
      })
    }
    this.setState({showForm})
  }

  showForm = (showForm) => {
    this.setState({showForm})
  }
  render() {
    const {showForm} = this.state

    return [
      <Alert />,
      <AuthPage>
        <img alt='king of the tee' width='300px' src="//lvg.s3.us-east-2.amazonaws.com/text-and-golfer-purp.svg" />
        {showForm === 'login' ? (
          <LoginForm showForm={this.showForm} history={this.props.history} />
        ) : (
          <Registration showForm={this.showForm} history={this.props.history} />
        )}
      </AuthPage>
    ]
  }
}

const AuthPage = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin: 0 60px;
  }
`

export default connect(
  state => ({}),
  dispatch => ({
    login: bindActionCreators(login, dispatch),
    notifySuccess: bindActionCreators(success.bind(null, 'api_success'), dispatch),
    notifyError: bindActionCreators(error.bind(null, 'api_error'), dispatch)
  })
)(Auth)
