import { combineReducers } from 'redux';
// import errors from './errors';
import notifications from './notifications';
// import {reducer as formReducer} from 'redux-form';
import resources from './resources';
import matchmaking from './matchmaking';
import feed from './feed';
// import auth from './auth';
// import {routerReducer} from 'react-router-redux';

export default combineReducers({
  feed,
  resources,
  matchmaking,
  notifications
});
