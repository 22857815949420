import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  height,
  minHeight,
  minWidth,
  alignSelf,
  space,
  width,
  alignItems,
  alignContent,
  justifyContent,
  flexWrap,
  flexDirection,
  flex,
  justifySelf,
  maxWidth,
  maxHeight,
  color,
  position,
  zIndex,
  borderRadius,
  order,
  display,
  opacity,
  top,
  left,
  bottom,
  right,
  borders,
  backgroundImage,
  backgroundSize,
  background
} from "styled-system";

export const Box = styled.div`
  ${background}
  ${alignItems}
  ${justifyContent}
  ${flexWrap}
  ${top}
  ${bottom}
  ${right}
  ${left}
  ${space}
  ${borders}
  ${width}
  ${height}
  ${maxWidth}
  ${maxHeight}
  ${color}
  ${position}
  ${zIndex}
  ${borderRadius}
  ${order}
  ${display}
  ${opacity}
  ${backgroundImage}
  ${backgroundSize}
  transition: opacity .3s ease;
`;

export const Flex = styled.div`
  ${background}
  ${top}
  ${bottom}
  ${right}
  ${left}
  ${space}
  ${width}
  ${height}
  ${minHeight}
  ${minWidth}
  ${alignItems}
  ${alignContent}
  ${justifyContent}
  ${flexWrap}
  ${alignSelf}
  ${flex}
  ${justifySelf}
  ${flexDirection}
  ${maxWidth}
  ${maxHeight}
  ${color}
  ${position}
  ${zIndex}
  ${borderRadius}
  ${borders}
  ${order}
  ${display}
  ${backgroundImage}
  ${backgroundSize}
  display: flex;
  cursor: ${props => (props.cursor ? props.cursor : "inherit")};
  overflow: ${props => (props.overflow ? props.overflow : "visible")};
  transform: ${props => props.transform || ''};
`;

const GridStyled = styled(Box)`
  max-width: ${props => props.fw ? '100%' : '1220px'};
  margin-right: auto;
  margin-left: auto;
`;

export const Container = props => (
  <GridStyled {...props} px={[15, 48, 48]}>
    {props.children}
  </GridStyled>
);

Container.propTypes = {
  children: PropTypes.node.isRequired
};

const RowStyled = styled.div`
  ${borders};
  ${space};
  ${justifyContent};
  ${alignItems};
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
`;

export const Row = props => {
  const scale = Array.isArray(props.scale)
    ? props.scale.map(x => x * 4)
    : props.scale * 4;
  return (
    <RowStyled py={scale} {...props}>
      {props.children}
    </RowStyled>
  );
};

Row.defaultProps = {
  scale: 1
};

Row.propTypes = {
  borderBottom: PropTypes.string,
  children: PropTypes.node.isRequired,
  scale: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ])
};

export const Col = styled(Flex)`
  ${space};
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: column;
`;

Col.displayName = "Col";

Col.propTypes = {
  children: PropTypes.node.isRequired,
  ...space.propTypes
};
