import React, {Component} from 'react';
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actions} from '../../actions/resources'
import {setTeeTimeModal} from '../../actions/matchmaking'
import {getGolfer} from '../../utils/golfer'
import theme from '../../styles/theme'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

class Account extends Component {
  componentDidMount() {
    const {fetchGolfer} = this.props
    if (getGolfer()) {
      fetchGolfer(getGolfer().id)
    }
  }

  render() {
    return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand
          style={{fontSize: '28px', fontFamily: 'Harley Style', cursor: 'pointer'}}
          onClick={() => this.props.history.push(this.props.golfer ? '/account' : '/')}>
          King of the Tee
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link> */}
            {this.props.golfer &&
              <NavDropdown title={`${this.props.golfer.email}`} id="collasible-nav-dropdown">
                <NavDropdown.Item onClick={() => this.props.history.push('/account')}>My Account</NavDropdown.Item>
                <NavDropdown.Item onClick={() => this.props.history.push('/account/teams')}>My Teams</NavDropdown.Item>
                <NavDropdown.Item onClick={() => this.props.history.push('/account/events')}>My Events</NavDropdown.Item>
                <NavDropdown.Item onClick={() => this.props.history.push('/events')}>View All Events</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => this.props.history.push('/logout')}>Logout</NavDropdown.Item>
              </NavDropdown>
            }
          </Nav>
          <Nav>
            {this.props.golfer ? (
              <Nav.Link style={{fontWeight: 'bold', color: theme.colors.gold}} onClick={() => this.props.setTeeTimeModal(true)}>Get a Tee Time</Nav.Link>
            ) : [
              <Nav.Link onClick={() => this.props.history.push('/about')}>About</Nav.Link>,
              <Nav.Link onClick={() => this.props.history.push('/auth')}>Login/Register</Nav.Link>
            ]}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default connect(
  state => ({
    golfer: state.resources.detail.golfer
  }),
  dispatch => ({
    fetchGolfer: bindActionCreators(actions.fetchGolfer, dispatch),
    createTeam: bindActionCreators(actions.createTeam, dispatch),
    setTeeTimeModal: bindActionCreators(setTeeTimeModal, dispatch),
  })
)(Account)
