import React, {Component} from 'react';
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {actions} from '../../actions/resources'
import {success, error} from '../../actions/notifications'
import styled from 'styled-components'
import {Col, Flex, Box, Container} from '../../styles/grid'
import theme from '../../styles/theme'
import Header from '../../components/common/header'
import Alert from '../../components/common/Alert'
import RegisterForm from '../auth/register-form'
import moment from 'moment'
import MapContainer from './map'

class EventDetail extends Component {
  state = {
    showRegistration: false,
    selectedTeam: '',
    showConfirmation: false,
    golferIsRegistered: false
  }

  componentDidMount() {
    const {fetchEvent, match, golfer} = this.props
    fetchEvent(match.params.id).then((response) => {
      if (response) {
        let event = response.payload
        if (golfer && golfer.events.find(e => e.id === event.real_id)){
          this.setState({
            golferIsRegistered: true
          })
        }
      }
    })
  }

  _handleRegisterClick = () => {
    this.setState({
      showRegistration: true
    })
  }

  _handleTeamSelect = (e) => {
    this.setState({
      selectedTeam: e.target.value
    })
  }

  submitRegistration = () => {
    const {selectedTeam} = this.state;
    const {golfer, fetchGolfer, notifySuccess, createEventRegistration, event} = this.props;

    createEventRegistration({event_id: event.id, team_id: selectedTeam}).then((response) => {
      notifySuccess('HELL YESH! You have registered successfully.')
      this.setState({
        showRegistration: false,
        showConfirmation: true
      })

      setTimeout(() => {
        this.setState({
          showConfirmation: false
        })
      }, 9000)
    })
  }

  handleRegisterSubmit = () => {

  }

  render() {
    const {event, golfer} = this.props;
    const {showRegistration, showConfirmation, golferIsRegistered} = this.state;

    if (event) {
      return [
        <Header history={this.props.history} />,
        <Alert />,
        <TopHero bg={event.background_image}>
          <GoBack onClick={() => this.props.history.push('/events')}> Back to Events</GoBack>
          <Container height={'100%'}>
            <Flex flexDirection={'column'} width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
              <Date>{moment(event.start_date).format('MM/DD/YYYY')}</Date>
              <ClubName>{event.club_name}</ClubName>
              <Location>{event.city_state}</Location>
            </Flex>
            <Register disabled={golferIsRegistered} onClick={this._handleRegisterClick}>
              {golferIsRegistered ? 'Registered' : 'Register'}
            </Register>
          </Container>
        </TopHero>,
        <MidSection>
          <Container>
            {showConfirmation &&
              <HellYesh width="320px" src="//media1.tenor.com/images/68efdf08c0b12dda3cc0193d09ea2a02/tenor.gif?itemid=5123398" />
            }
            {showRegistration ? (
              <Flex flexDirection={'column'} width={'100%'} minHeight={350} justifyContent={'center'} alignItems={'center'}>
                <RegisterHeader>Which team would you like to register with?</RegisterHeader>
                <EventDescription>
                  This is a pre-registration and does not require any payment.
                </EventDescription>
                {golfer ? (
                  golfer.teams.length ? (
                    <Col>
                      <select value={this.state.selectedTeam} onChange={this._handleTeamSelect}>
                        <option value={''}>Choose a Team</option>
                        {golfer.teams.map(t => (
                          <option value={t.id}>
                            {t.name}
                          </option>
                        ))}
                      </select>
                      <SubmitRegistration onClick={this.submitRegistration} disabled={!this.state.selectedTeam}>
                        Submit Registration
                      </SubmitRegistration>
                    </Col>
                  ) : (
                    <Box>
                      <p>Looks like you havent made any teams yet, <Link to="/account/teams">Create a new team here</Link>.</p>
                    </Box>
                  )
                ) : (
                  <Box>
                    <RegisterHeader>Create an account</RegisterHeader>
                    <RegisterForm history={this.props.history} inline handleSubmit={this.handleRegisterSubmit} />
                  </Box>
                )}
              </Flex>
            ) : (
              <Flex flexDirection={'column'} width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
                <Logo src="//lvg.s3.us-east-2.amazonaws.com/kott/logos/kott-clean.png" />
                <EventName>{event.name}</EventName>
                <Flex mt={30} justifyContent={'space-around'} width={[320, 640]}>
                  <Detail color={theme.colors.blue}>
                    {event.format.replace('_', ' ').replace('_', ' ')}
                  </Detail>
                  <Detail color={theme.colors.gold}>
                    11-15 Handicap
                  </Detail>
                  <Detail color={theme.colors.gold}>
                    ${event.price_per_player} Entry Fee
                  </Detail>
                  <Detail color={theme.colors.red}>
                    19 Points
                  </Detail>
                </Flex>
                <EventDescription>
                  {event.description}
                </EventDescription>

                <Col alignItems={'center'} width={[320, 640]} mt={80}>
                  <PeopleHeader>
                    110/150 People Attending
                  </PeopleHeader>
                </Col>
              </Flex>
            )}
          </Container>
        </MidSection>,
        <MapSection>
          <MapContainer event={event} />
        </MapSection>
      ]
    }
    return null
  }
}
const HellYesh = styled.img`
  margin: auto;
  display: block;
`

const SubmitRegistration = styled.button`
  height: 60px;
  width: 250px;
  color: #fff;
  background-color: ${theme.colors.darkGray};
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
  transition: all 0.2s linear;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const RegisterHeader = styled.h3`

`

const GoBack = styled.button`
  background: rgba(255,255,255,0.6);
  height: 40px;
  font-size: 18px;
  position: fixed;
  left: 30px;
  top: 80px;
  border: 2px solid #fff;
  border-radius: 30px;
  color: ${theme.colors.blue};
  padding: 0 20px;
  &:hover {
    background-color: #fff;
  }
`

const PeopleHeader = styled.h3``

const EventDescription = styled.p`
  margin-top: 30px;
  width: 600px;
  text-align: center;

  @media (max-width: 760px) {
    width: 90%;
  }
`

const Detail = styled.span`
  font-size: 16px;
  color: ${props => props.color};
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
`

const Register = styled.button`
  width: 245px;
  height: 67px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  background-color: ${theme.colors.purple};
  border: none;
  position: absolute;
  bottom: -33px;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.67);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.67);
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.67);

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`

const Location = styled.span`
  font-size: 26px;
  font-weight: 300;
  color: #fff;
`

const ClubName = styled.span`
  font-size: 50px;
  line-height: 1;
  font-weight: 300;
  color: #fff;
  text-align: center;
  display: block;
  margin: 10px 0
`

const EventName = styled.h2`
  font-size: 30px;
  line-height: 1;
  font-weight: 300;
  color: ${theme.colors.darkGray};
  text-align: center;
  display: block;
  margin: 10px 0
`

const Date = styled.span`
  font-size: 40px;
  font-weight: bolder;
  line-height: 40px;
  color: #fff;
`

const TopHero = styled.div`
  position: relative;
  height: 60vh;
  background: url(${props => props.bg})no-repeat center center;
  background-size: cover;

  @media (max-width: 760px) {
    height: 80vh;
  }
`
const MidSection = styled.div`
  padding: 80px 0;
  min-height: 300px;
`
const MapSection = styled.div`
  width: 100vw;
  height: 800px;
`

const Logo = styled.img`
  width: 120px;
  height: auto;
  display: block;
`

export default connect(
  state => ({
    event: state.resources.detail.event,
    golfer: state.resources.detail.golfer
  }),
  dispatch => ({
    fetchEvent: bindActionCreators(actions.fetchEvent, dispatch),
    fetchEventRegistrations: bindActionCreators(actions.fetchEvent_registrations, dispatch),
    createEventRegistration: bindActionCreators(actions.createEvent_registration, dispatch),
    notifySuccess: bindActionCreators(success.bind(null, 'api_success'), dispatch),
    notifyError: bindActionCreators(error.bind(null, 'api_error'), dispatch)
  })
)(EventDetail)
