import React from 'react'
import Home from './containers/marketing/home'
import About from './containers/marketing/about'
import Auth from './containers/auth'
// import SetPassword from './containers/auth/set-password'
import Account from './containers/account'
import Events from './containers/events'
import EventDetail from './containers/events/detail'
import {Switch, Route} from 'react-router-dom'
import LogoutUser from './containers/auth/logout'


function AppRoutes() {
  return [
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/account" component={Account} />
      <Route path="/auth" exact component={Auth} />
      <Route path="/about" exact component={About} />
      {/* <Route path="/set_password" exact component={SetPassword} /> */}
      <Route path="/events/:id" exact component={EventDetail} />
      <Route path="/events" exact component={Events} />
      <Route path="/register" exact component={Auth} />
      <Route path="/login" exact component={Auth} />
      <Route path="/logout" exact component={LogoutUser} />
    </Switch>
  ]
}

export default AppRoutes
