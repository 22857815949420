export default {
  breakpoints: ["769px", "1120px"],
  colors: {
    gold: '#FFC857',
    purple: '#6501DB',
    red: '#C5283D',
    blue: '#43BCCD',
    darkGray: '#4a4a4a',
    lightGreen: '#1DD3B0'
  }
}
