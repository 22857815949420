import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {actions} from '../../actions/resources'
import {fetchEspnFeed} from '../../actions/feed'
import styled from 'styled-components'
import GolferForm from '../../components/golfer/form'
import MainAccountContent from './main-content'
console.log(actions)
class AccountHome extends Component {
  state = {
    newGolfer: false
  }
  componentDidMount() {
    const {fetchEspnFeed, golfer} = this.props
    fetchEspnFeed()
    if (!golfer.first_name) {
      this.setState({
        newGolfer: true
      })
    }
  }

  _openingText = () => {
    const {newGolfer} = this.state;
    const {golfer} = this.props;
    if (newGolfer) {
      return "Welcome to King of the Tee. Looks like you havent quite finished your profile, use the form below to do so."
    } else {

      return (
        <AccountHeader>
          <PageTitle>
            Welcome to King of the Tee
          </PageTitle>
        </AccountHeader>
      )
    }
  }
  render() {
    const {golfer} = this.props;
    return (
      <Home>
        <IntroParagraph>
          {this._openingText()}
        </IntroParagraph>
        {golfer.first_name && golfer.last_name ? (
          <MainAccountContent {...this.props} />
        ) : (
          <GolferForm {...this.props} />
        )}
      </Home>
    )
  }
}

const Home = styled.div``
const AccountHeader = styled.div``
const PageTitle = styled.p`
  font-size: 18px;
`
const IntroParagraph = styled.p`
  font-size: 22px;
`

export default connect(
  state => ({
    courses: state.resources.collection.course,
    espnFeed: state.feed.espnFeed
  }),
  dispatch => ({
    fetchCourses: bindActionCreators(actions.fetchCourses, dispatch),
    fetchEspnFeed: bindActionCreators(fetchEspnFeed, dispatch),
    createGolferRound: bindActionCreators(actions.createGolfer_round, dispatch)
  })
)(AccountHome)
