import {Cookies} from 'react-cookie'
const cookies = new Cookies()

export const makeAuthHeader = () => {
	let golfer = cookies.get(process.env.REACT_APP_GOLFER_COOKIE);
	if (golfer) {
	  return {
			'uid': golfer.email,
	  	'access-token': golfer.authentication_token,
      'expiry': golfer.expiry,
      'client': golfer.client
	  }
	}
  return null;
};
