import React from 'react'
import {Flex, Box} from '../../styles/grid'
import theme from '../../styles/theme'
import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'

function AccountSidebar(props) {
  return (

      <Box pt={[0, 40]} height={window.innerHeight} borderRight={'1px solid #ddd'} width={['100%', '30%']}>
        <Logo src="//lvg.s3.us-east-2.amazonaws.com/kott-logo-9-12-2020.svg" />
        {props.golfer && [
          <Nav>
            <Flex alignItems={'center'} mt={40} flexDirection={'column'}>
              {props.golfer.status === 'looking_for_game' ? (
                <Box mb={20}>
                  <center>
                    <Spinner animation="grow" variant="dark" />
                    <Spinner animation="grow" variant="dark" /><br/>
                    <Lfg>Looking for<br/> Game</Lfg>
                  </center>
                </Box>
              ) : (
                <NavButton onClick={props.openScoreModal}>
                  Add Score
                </NavButton>
              )}
              <NavLink active={props.history.location.pathname === '/account'} onClick={() => props.history.push('/account')}>Account</NavLink>
              <NavLink active={props.history.location.pathname === '/account/teams'} onClick={() => props.history.push('/account/teams')}>My Teams</NavLink>
              <NavLink active={props.history.location.pathname === '/account/events'} onClick={() => props.history.push('/account/events')}>My Events</NavLink>
              <NavLink onClick={() => props.history.push('/events')}>Find Events</NavLink>
            </Flex>
          </Nav>,
          <AuthLinks>
            <Link to="/logout">Logout</Link>
          </AuthLinks>
        ]}
      </Box>
  )
}

const Lfg = styled.span`
  font-family: 'Harley Style';
  font-size: 26px;
  line-height: 1.2;
  text-transform: uppercase;
  color: ${theme.colors.gold};
`

const NavButton = styled.button`
  background-color: ${theme.colors.gold};
  color: ${theme.colors.darkGray};
  width: 200px;
  padding: 0 20px;
  height: 40px;
  margin-bottom: 40px;
  font-weight: bold;
  appearance: none;
  -webkit-appearance: none;
  border: none;
`

const AuthLinks = styled.div`
  margin-top: 100px;
  a {
    width: 100px;
    text-align: center;
    display: block;
    margin: auto;
    opacity: 0.8;
    color: #4f4f4f;
    text-decoration: none;
    font-size: 13px;
  }
`

const NavLink = styled.span`
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  height: 25px;
  color: ${props => props.color || '#4f4f4f'};
  transition: all 0.3s linear;
  &:hover {
    opacity: 0.76;
  }

  ${props => props.active && css`
    font-weight: bold;
  `}
`

const Logo = styled.img`
  width: 200px;
  height: auto;
  margin: auto;
  display: block;
`
const Nav = styled.nav`

`

export default AccountSidebar
