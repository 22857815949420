import React, {Component} from 'react';
import styled from 'styled-components'
import {actions} from '../../actions/resources'
import {success, error} from '../../actions/notifications'
import {EMAIL_REGEX} from '../../utils/regex'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Alert from '../../components/common/Alert'
import theme from '../../styles/theme'

class Home extends Component {
  state = {
    email: '',
    zipcode: ''
  }

  _updateEmail = (email) => {
    this.setState({email})
  }

  _updateZipcode = (zipcode) => {
    this.setState({zipcode})
  }

  _submitLead = () => {
    const {email, zipcode} = this.state
    if (EMAIL_REGEX.test(email)) {
      this.props.createLead({lead: {email, zipcode}}).then((response) => {
        if (response) {
          this.props.notifySuccess('Woohoo! Thanks for signing up, stay tuned for updates.')
          this.setState({
            email: '',
            zipcode: ''
          })
        }
      })
    } else {
      this.props.notifyError('Please enter a valid email.')
    }
  }

  render () {
    const {email, zipcode} = this.state

    return (
      <AppContainer>
        <Alert {...this.props} />
        <LandingPage>
          <HeroSection>
            <Title src= "//lvg.s3.us-east-2.amazonaws.com/kott-text-and-golfer.svg" />
            <LaunchingText>
              <center><span>Launching Q1 2020</span></center>
            </LaunchingText>
            <Text>
              A player-driven mini-tour for all skill levels. <br/>
              Give us your email below for updates about tournaments near you!
            </Text>
            <LearnMore onClick={() => this.props.history.push('/about')}>Learn More</LearnMore>
            <ActionBar>
              {/* <img alt='golf ball' src="//lvg.s3.us-east-2.amazonaws.com/kott/icons8-golf-ball-100.png" /> */}
              <Input value={email} onChange={(e) => this._updateEmail(e.target.value)} key={'email'} placeholder={'Email Address'} type='email' />
              <Input value={zipcode} onChange={(e) => this._updateZipcode(e.target.value)} key={'zipcode'} placeholder={'Zip Code'} type='text' />
              <OutlineButton onClick={this._submitLead}>
                Submit
              </OutlineButton>
            </ActionBar>
            <SignInLink href="#" role="button" onClick={() => this.props.history.push('/auth')} >
              Sign In / Register
            </SignInLink>
          </HeroSection>
        </LandingPage>
      </AppContainer>
    );
  }
}

const LearnMore = styled.button`
  font-size: 16px;
  color: ${theme.colors.gold};
  appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  font-weight: bold;
  margin-top: 20px;

  &:hover {
    opacity: 0.8;
  }
`

const SignInLink = styled.a`
  color: #ddd;
  font-size: 16px;
  display: block;
  position: absolute;
  top: 30px;
  right: 30px;
  margin: auto;
  width: fit-content;
  padding-bottom: 6px;
  border-bottom: 2px solid #ddd;
  text-decoration: none;
`
const Text = styled.span`
  font-size: 26px;
  line-height: 1.5;
  margin-top: 20px;
  color: #fff;
  text-align: center;
`

const Input = styled.input`
  width: 35%;
  height: 70px;
  border: none;
  border-bottom: 2px solid #fff;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
  font-size: 28px;
  color: #fff;
  padding-left: 17px;
  &::placeholder, ::-webkit-input-placeholder {
    color: #f6f6f6;
  }
  @media (max-width: 760px) {
    width: 100%;
  }
`

const OutlineButton = styled.button`
  background: transparent;
  border: 2px solid #fff;
  height: 70px;
  width: 20%;
  border-radius: 8px;
  font-size: 28px;
  color: #fff;
  cursor: pointer;
  transition: all 0.23s linear;
  @media (max-width: 760px) {
    width: 100%;
    margin-top: 15px;
  }

  &:hover {
    background: rgba(255,255,255,0.35);
  }
`

const ActionBar = styled.div`
  display: flex;
  margin-top: 140px;
  width: 800px;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  @media (max-width: 760px) {
    margin-top: 20px;
    width: 300px;
  }
`

const LaunchingText = styled.span`
  position: absolute;
  left: 30px;
  top: 30px;
  color: ${theme.colors.blue};
  font-size: 18px;
  font-weight: bolder;
`

const HeroSection = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 40px;
  box-sizing: border-box;
  background: rgba(0,0,0, 0.6);
  @media (max-width: 760px) {
    padding: 80px 10%;
  }
`

const Title = styled.img`
  margin: 0;
  padding: 0;
  width: 350px;
  line-height: 1.2;
  text-align: center;
  font-family: 'Cinzel', serif;
  @media (max-width: 760px) {
    width: 200px;
  }
`

const AppContainer = styled.div`
  width: 100vw;
`

const LandingPage = styled.div`
  min-height: 100vh;
  width: 100%;
  background: url(//lvg.s3.us-east-2.amazonaws.com/kott/kott-tee-off.jpg)no-repeat center center;
  background-size: cover;
`

export default connect(
  state => ({

  }),
  dispatch => ({
    createLead: bindActionCreators(actions.createLead, dispatch),
    notifySuccess: bindActionCreators(success.bind(null, 'api_success'), dispatch),
    notifyError: bindActionCreators(error.bind(null, 'api_error'), dispatch)
  })
)(Home);
