export function setTeeTimeModal (payload) {
  return {
    type: 'SET_TEE_TIME_MODAL',
    payload
  };
}


export function setScoreModal (payload) {
  return {
    type: 'SET_SCORE_MODAL',
    payload
  };
}
