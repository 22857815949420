import React, {Component} from 'react'
import styled from 'styled-components'

const inputStyle = {
  height: '45px',
  width: '100%',
  marginBottom: '35px',
  paddingLeft: '15px'
}

const validate = (values) => {
  let errors = {}
  if (!values.first_name) {
    errors.email = 'Required';
  } else if (!values.first_name) {
    errors.first_name = 'Required';
  } else if (!values.last_name) {
    errors.last_name = 'Required';
  }
  return errors;
}

class MainAccountContent extends Component {
  state = {
    selectedCourse: '',
    selectedTee: ''
  }

  _espnFeed = () => {
    const {golfer, espnFeed} = this.props;
    return (
      espnFeed && espnFeed.map(item => (
        <NewsFeedCard>
          <CardImage src={item.image[0]} />
          <CardTitle>{item.title[0]}</CardTitle>
          <CardDescription>
            {item.description[0]} <a href={item.link[0]} rel="noopener noreferrer" target="_blank">View More</a>
          </CardDescription>
        </NewsFeedCard>
      ))
    )
  }
  render() {
    const {golfer, espnFeed, createGolferRound} = this.props;
    return (
      <FeedContainer>
        <HomeFeed>

        </HomeFeed>
        <NewsFeed>
          {this._espnFeed()}
        </NewsFeed>
      </FeedContainer>
    )
  }
}

const AddScoreButton = styled.button`

`

const HomeFeed = styled.div`
  width: 70%;
`
const CardDescription = styled.p`
  font-size: 12px;
  opacity: 0.7;
`

const NewsFeed = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: ${window.innerHeight - 60}px;
`

const NewsFeedCard = styled.div`
  width: 70%;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 3%;
`

const CardTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 1.2em;
  margin-top: 8px;
`

const FeedContainer = styled.div`
  display: flex;
  justify-content: space-space-between;
  height: ${window.innerHeight - 60}px;
  overflow: auto;
`

const CardImage = styled.img`
  width: 100%;
`


const Label = styled.label`
  font-weight: bolder;
`

export default MainAccountContent
