exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./fonts/HarleyStyleBrush.eot"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./fonts/HarleyStyleBrush.ttf"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./fonts/HarleyStyleBrush.woff"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./fonts/HarleyStyleBrush.woff2"));

// Module
exports.push([module.id, "@font-face {\n  font-family: 'Harley Style';\n  font-style: normal;\n  src: url(" + ___CSS_LOADER_URL___0___ + ");\n  src: local('Harley Style'), local('HarleyStyle'),\n    url(" + ___CSS_LOADER_URL___1___ + ").format('truetype'),\n    url(" + ___CSS_LOADER_URL___2___ + ").format('woff'),\n    url(" + ___CSS_LOADER_URL___3___ + ").format('woff2'),\n}\n", ""]);

