import React, {Component} from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'
import {Box, Flex} from '../../styles/grid'
import theme from '../../styles/theme'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {setTeeTimeModal} from '../../actions/matchmaking'
import {actions} from '../../actions/resources'
import DatePicker from 'react-datepicker'
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import addDays from "date-fns/addDays";
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'
import _ from 'underscore'

const customStyles = {
  content : {
    width                 : '600px',
    height                : '600px',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

Modal.setAppElement('#root')

class TeeTimeModal extends Component {
  state = {
    mode: '',
    startDate: addDays(new Date(), 2),
    showConfirmation: false,
    submitting: false,
    numberOfGolfers: 2,
    partners: []
  }

  _closeModal = () => {
    this.props.setTeeTimeModal(false)
    this.setState({
      mode: '',
      startDate: addDays(new Date(), 2),
      showConfirmation: false,
      submitting: false
    })
  }

  _setMode = (mode) => {
    this.setState({mode})
  }

  _handleChange = (e) => {
    this.setState({
      startDate: new Date(e)
    })
  }

  _makeTeeTime = () => {
    const {golfer, patchGolfer} = this.props;
    const {startDate, mode, partners} = this.state;
    this.setState({
      submitting: true
    })
    let golferPartners = []

    if (mode === 'group') {
      golferPartners = partners
    }
    let preferences = {
      tee_time: {
        playing_date: moment(startDate).format(),
        mode: 'solo',
        partners: golferPartners
      }
    }

    let payload = {
      status: 'looking_for_game',
      preferences: preferences
    }

    patchGolfer(golfer.id, payload).then((response) => {
      this.setState({
        submitting: false
      })
      if (response.payload.status === 'looking_for_game') {
        this.setState({
          showConfirmation: true
        })
      }
    })
  }

  _handlePlayerSelectChange = (e) => {
    this.setState({
      numberOfGolfers: e.target.value
    })
  }

  _renderConfirmation = () => {
    return (
      <Flex position={'relative'} height={'100%'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
        <Emblem src="//lvg.s3.us-east-2.amazonaws.com/kott/logos/kott-clean.png" />
        <ConfirmationHeader>
          Boom!
        </ConfirmationHeader>
        <ConfirmationText>
          We are setting up your round now, check your email/account page to get
          details on course and tee time.
          <br />
          <br />
          <GoBack onClick={this._closeModal}>
            Back to Account
          </GoBack>
        </ConfirmationText>
      </Flex>
    )
  }

  _updatePartners = (value, index) => {
    this.setState((currentState) => {
      let newState = currentState
      newState.partners[index] = value
      return newState
    })
  }

  _renderMainContent = () => {
    const {mode, startDate, submitting, numberOfGolfers} = this.state;
    const {golfer} = this.props;
    if (mode) {
      return (
        <Flex justifyContent={'center'} p={40} flexDirection={'column'} width={['100%']}>
          <Heading>
            What day & time would you like to play?
          </Heading>
          <Subheader>
            Please allow up to an hour leeway for your preferred time.
          </Subheader>
          <DpWrapper>
            <DatePicker
              selected={this.state.startDate}
              onChange={this._handleChange}
              minDate={addDays(new Date(), 2)}
              maxDate={addDays(new Date(), 10)}
              minTime={setHours(setMinutes(new Date(), 0), 6)}
              maxTime={setHours(setMinutes(new Date(), 0), 18)}
              showTimeSelect
              timeIntervals={15}
              dateFormat="MMM dd, yyyy h:mm aa"
              timeCaption="Tee time"
            />
          </DpWrapper>
          {mode === 'solo' ? (
            <Flex mt={50} alignItems={'center'} flexDirection={'column'}>
              <Review>
                Review Upcoming Round
              </Review>
              <ReviewInfo>
                <b>1 Golfer</b>, {moment(startDate).format('dddd, MMMM Do YYYY')}
              </ReviewInfo>
              <Location>
                <b>Near Zipcode:</b> {golfer.zipcode}
              </Location>

              <MakeTeeTime onClick={this._makeTeeTime}>
                {submitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Setup Round'
                )}
              </MakeTeeTime>

              <GoBack onClick={() => this._setMode('')}>
                Go Back
              </GoBack>
            </Flex>
          ) : (
            <Flex mt={30} alignItems={'center'} flexDirection={'column'}>
              <Review>
                Review Upcoming Round
              </Review>
              <Flex mt={20} justifyContent={'space-between'} width={'100%'}>
                <Flex display={'flex'} flexDirection={'column'}>
                  <b>Golfers</b>
                  <ReviewInfo>
                    <PlayerSelect value={this.state.numberOfGolfers} onChange={this._handlePlayerSelectChange}>
                      <option value={2}>2 golfers</option>
                      <option value={3}>3 golfers</option>
                      <option value={4}>4 golfers</option>
                    </PlayerSelect>
                  </ReviewInfo>
                  <Flex flexDirection={'column'} mt={20}>
                    <li><span>{golfer.first_name} {golfer.last_name}</span></li>
                    {_.times(numberOfGolfers-1, (i) => (
                      <li><PartnerInput onChange={(e) => this._updatePartners(e.target.value, i)} placeholder={`Golfer ${i+2} email`} key={i} type="text" /></li>
                    ))}
                  </Flex>
                </Flex>
                <Location>
                  <div>
                    <b>Date:</b><br /> {moment(startDate).format('dddd, MMMM Do YYYY')}
                  </div>
                  <div>
                    <b>Budget:</b><br /> $69
                  </div>
                  <div>
                    <b>Near Zipcode:</b><br /> {golfer.zipcode}
                  </div>
                </Location>
              </Flex>

              <MakeTeeTime onClick={this._makeTeeTime}>
                {submitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Setup Round'
                )}
              </MakeTeeTime>

              <GoBack onClick={() => this._setMode('')}>
                Go Back
              </GoBack>
            </Flex>
          )}
        </Flex>
      )
    } else {
      return (
        <Flex height={'100%'} alignItems={'center'} justifyContent={'space-around'} flexWrap={'wrap'}>
          <ModeSelect onClick={() => this._setMode('solo')}>
            <ModeTitle>Solo</ModeTitle>
            <ModeSubtitle>Setup a round for yourself</ModeSubtitle>
          </ModeSelect>

          <ModeSelect onClick={() => this._setMode('group')}>
            <ModeTitle>Group</ModeTitle>
            <ModeSubtitle>Setup a round for you & your buddies</ModeSubtitle>
          </ModeSelect>
        </Flex>
      )
    }
  }

  render() {
    const {showConfirmation, partners} = this.state;
    console.log(partners)
    return (
      <Modal
        isOpen={this.props.teeTimeModalOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this._closeModal}
        contentLabel="Get a Game"
        style={customStyles}
      >
        <CloseIcon onClick={this._closeModal}>x</CloseIcon>
        {showConfirmation ? this._renderConfirmation() : this._renderMainContent()}
      </Modal>
    )
  }
}

const PlayerSelect = styled.select`
  width: 100%;
  height: 35px;
  background: none;
  border: none;
  border-bottom: 1px solid #ddd;
  &:focus {
    outline: none;
  }
`

const CloseIcon = styled.span`
  display: block;
  position: absolute;
  right: 20px;
  top: 15px;
  font-family: 'Harley Style';
  font-size: 40px;
  line-height: 40px;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
  z-index: 100000;
`

const Emblem = styled.img`
  width: 60px;
  height: auto;
  position: absolute;
  left: 20px;
  top: 20px;
`

const GoBack = styled.button`
  background: none;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  color: ${theme.colors.blue};
  font-weight: bold;
`

const ConfirmationHeader = styled.h3`
  font-family: 'Harley Style';
  font-size: 75px;
  text-align: center;
`

const ConfirmationText = styled.p`
  width: 300px;
  text-align: center;
`

const MakeTeeTime = styled.button`
  width: 240px;
  height: 65px;
  font-size: 20px;
  color: ${theme.colors.darkGray};
  font-weight: bold;
  border-radius: 4px;
  border: none;
  background-color: ${theme.colors.gold};
  margin: 40px 0;
  &:hover {
    opacity: 0.7;
  }
`

const Location = styled.span`
  display: block;
  width: 50%;
`

const Review = styled.span`
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  line-height: 40px;
`
const ReviewInfo = styled.span`
  text-align: center;
`

const ModeSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 20px;
  width: 200px;
  height: 200px;
  border: 1px solid #ddd;
  transition: all 0.3s linear;
  cursor: pointer;
  &:hover {
    border: 1px solid ${theme.colors.gold};
    transform: translateY(-3px);
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.67);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.67);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.17);
  }
`

const ModeTitle = styled.h4`
  text-align: center;
  font-size: 22px;
  font-weight: bold;
`

const ModeSubtitle = styled.span`
text-align: center;

`

const DpWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;

  input {
    cursor: pointer;
    display: block;
    margin: auto;
    padding-left: 15px;
    height: 50px;
    width: 300px;
  }
`

const Heading = styled.h3`
  text-align: center;
`

const Subheader = styled.span`
  text-align: center;
`

const PartnerInput = styled.input`
  border: none;
  border-bottom: 1px solid #ddd;
  height: 35px;
  font-size: 16px;
  &:focus {
    outline: none;
  }
`

export default connect(
  state => ({
    teeTimeModalOpen: state.matchmaking.teeTimeModalOpen,
    golfer: state.resources.detail.golfer
  }),
  dispatch => ({
    setTeeTimeModal: bindActionCreators(setTeeTimeModal, dispatch),
    patchGolfer: bindActionCreators(actions.patchGolfer, dispatch)
  })
) (TeeTimeModal)
