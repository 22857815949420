export const defaultState = {
  teeTimeModalOpen: false,
  scoreModalOpen: false
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);

  switch (action.type) {
    case 'SET_TEE_TIME_MODAL':
      newState.teeTimeModalOpen = action.payload
      return newState;
    case 'SET_SCORE_MODAL':
      newState.scoreModalOpen = action.payload
      return newState;
    default:
      return state;
  }
};
