export const defaultState = {
  espnFeed: ''
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);

  switch (action.type) {
    case 'RECEIVE_RSS_FEED':
    newState.espnFeed = action.payload
      return newState;
    default:
      return state;
  }
};
