import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {Flex, Box} from '../../styles/grid'
import theme from '../../styles/theme'
import {success, error} from '../../actions/notifications'
import {usStates} from '../../utils/location'
import Modal from 'react-modal'
import styled from 'styled-components'
import {setScoreModal} from '../../actions/matchmaking'
import {actions} from '../../actions/resources'
import _ from 'lodash'
const customStyles = {
  content : {
    width                 : '600px',
    height                : '600px',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};


class AddScoreModal extends Component {
  state = {
    search: '',
    selectedCourse: '',
    selectedTee: '',
    score: '',
    showCourses: false
  }

  afterOpenModal = () => {

  }

  closeModal = () => {
    this.props.setScoreModal(false)
  }

  _filteredClubs = () => {
    const {clubs} = this.props;
    const {search} = this.state

    return _.filter(clubs, club => club.name.toLowerCase().includes(search))
  }

  _selectCourse = (selectedCourse) => {
    const {fetchCourse} = this.props;
    this.setState({selectedCourse, showCourses: false})
    fetchCourse(selectedCourse.id)
  }

  _selectTee = (selectedTee) => {
    this.setState({selectedTee})
  }

  _addScore = () => {
    const {createGolferRound, golfer, notifySuccess} = this.props
    const {selectedTee, selectedCourse, score} = this.state
    const payload = {
      tee_id: selectedTee,
      course_id: selectedCourse.id,
      score: score,
      golfer_id: golfer.id
    }
    createGolferRound(payload).then(r => {
      if (r.payload) {
        notifySuccess('Score sucessfully added')
        this.setState({
          showConfirmation: true,
          selectedTee: '',
          selectedCourse: '',
          score: ''
        })
      }
    })
  }

  render () {
    const {course, scoreModalOpen, fetchClubs, clubs} = this.props
    const {showCourses, selectedCourse, selectedTee} = this.state
    let courses
    if (clubs) {
      courses = _.flattenDeep(clubs.map(club => club.courses))
    }
    return (
      <Modal
        isOpen={scoreModalOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        contentLabel="Add a Score"
        style={customStyles}
        >
          <CloseIcon onClick={this.closeModal}>x</CloseIcon>
          <Flex alignItems={'center'} flexDirection='column' width={[450]}>
            <Box width={[450]}>
              <select onChange={(e) => fetchClubs({state: e.target.value})}>
                <option value="">Select State</option>
                {usStates.map(state => (
                  <option value={state.label}>
                    {state.value}
                  </option>
                ))}
              </select>
            </Box>
            <Box position={'relative'} width={[450]}>
              {clubs &&
                <input onChange={(e) => this.setState({showCourses: true, search: e.target.value})} placeholder='Search courses' type='text' />
              }
              {showCourses &&
                <ClubMenu>
                  {this._filteredClubs().map(club => (
                    club.courses.map(course => (
                      <ClubOption onClick={() => this._selectCourse(course)}>
                        {course.name} <br/>
                        <small> {club.name}</small>
                      </ClubOption>
                    ))
                  ))}
                </ClubMenu>
              }
            </Box>
            {selectedCourse &&
              <Box width={[450]}>
                <select onChange={e => this._selectTee(e.target.value)}>
                  <option value="">Select Tee</option>
                  {course && course.tees.map(tee => (
                    <option value={tee.id}>{tee.name}</option>
                  ))}
                </select>
              </Box>
            }
            {selectedTee &&
              <Box>
                <input onChange={e => this.setState({score: e.target.value})} type='number' placeholder='enter score' />
              </Box>
            }
            <Box>
              <AddScoreButton onClick={this._addScore}>
                Add Score
              </AddScoreButton>
            </Box>
          </Flex>
        </Modal>
      )
  }
}

const ClubOption = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
  }
`

const AddScoreButton = styled.button`
  background-color: ${theme.colors.lightGreen};
  appearance: none;
  -webkit-appearance: none;
  border: none;
  color: ${theme.colors.darkGray};
`

const ClubMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right:0;
  margin: auto;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  padding-bottom: 10px;
  overflow: auto;
`

const CloseIcon = styled.span`
  display: block;
  position: absolute;
  right: 20px;
  top: 15px;
  font-family: 'Harley Style';
  font-size: 40px;
  line-height: 40px;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
  z-index: 100000;
`
export default connect(
  state => ({
    scoreModalOpen: state.matchmaking.scoreModalOpen,
    clubs: state.resources.collection.club,
    course: state.resources.detail.course,
    golfer: state.resources.detail.golfer
  }),
  dispatch => ({
    setScoreModal: bindActionCreators(setScoreModal, dispatch),
    fetchClubs: bindActionCreators(actions.fetchClubs, dispatch),
    fetchCourse: bindActionCreators(actions.fetchCourse, dispatch),
    createGolferRound: bindActionCreators(actions.createGolfer_round, dispatch),
    notifySuccess: bindActionCreators(success.bind(null, 'api_success'), dispatch)
  })
)(AddScoreModal)
