/* eslint camelcase: 0 */
const API_URL = process.env.REACT_APP_KOTT_API_URL;

export const resources = {
  club: {
    plural: 'clubs'
  },
  course: {
    plural: 'courses'
  },
  event: {
    plural: 'events'
  },
  event_registration: {
    plural: 'event_registrations'
  },
  golfer: {
    plural: 'golfers'
  },
  golfer_round: {
    plural: 'golfer_rounds'
  },
  lead: {
    plural: 'leads'
  },
  match: {
    plural: 'matches'
  },
  team: {
    plural: 'teams'
  },
  player: {
    plural: 'players'
  }
};

Object.keys(resources).forEach((key) => {
  let resource = resources[key];
  resource.url = `${API_URL}/${resource.plural}`;
});

export default resources;
