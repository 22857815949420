import axios from 'axios';
import {parseString} from 'xml2js'
export function fetchEspnFeed() {
  return function (dispatch) {
    dispatch({
      type: 'FETCH_PGA_RSS_FEED'
    })

    return axios.get("https://www.espn.com/espn/rss/golf/news", {
      "Content-Type": "application/xml; charset=utf-8"
    }).then((response) => {
      parseString(response.data, function (err, result) {
        let items = result.rss.channel[0].item
        return dispatch({
          type: 'RECEIVE_RSS_FEED',
          payload: items
        })
      });
    })
  }
}
