import React, {Component} from 'react';
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actions} from '../../actions/resources'
import styled from 'styled-components'
import {Box, Container} from '../../styles/grid'
import theme from '../../styles/theme'
import Card from 'react-bootstrap/Card'
import Header from '../../components/common/header'

import moment from 'moment'

class Events extends Component {
  componentDidMount() {
    const {fetchEvents} = this.props
    fetchEvents()
  }


  render() {
    const {events} = this.props;

    return [
      <Header {...this.props} />,
      <Container flexWrap={'wrap'} pt={80} minHeight={window.innerHeight} display={'flex'}>
        {events && events.length && events.map(e => (
          <Box height={[450]} width={['100%', '33%']} p={'0.5em'}>
          <Card style={{ height: '100%', width: '100%' }}>
              <Card.Img variant="top" src={e.background_image} />
              <Card.Body>
                <Card.Title>{e.name}</Card.Title>
                <Card.Text>
                  <EventFeature>{moment(e.start_date).format('dddd MM/YY')}</EventFeature>
                  <EventFeature>{e.format}</EventFeature>

                </Card.Text>
                <Button onClick={() => this.props.history.push(`/events/${e.id}`)}>View Event</Button>
              </Card.Body>
          </Card>
        </Box>
        ))}
      </Container>
    ]
  }
}

const EventFeature = styled.span`
  display: block;
  font-size: 16px;
`

const Button = styled.button`
  background-color: ${theme.colors.purple};
  color: #fff;
  font-size: 18px;
  height: 45px;
  border: none;
  border-radius: 4px;
  padding: 0 14px;
`

export default connect(
  state => ({
    events: state.resources.collection.event
  }),
  dispatch => ({
    fetchEvents: bindActionCreators(actions.fetchEvents, dispatch)
  })
)(Events)
