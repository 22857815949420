import React, {Component} from 'react'
import styled, {css} from 'styled-components'
import {connect} from 'react-redux'

class AlertBar extends Component {
  render() {
    let type = ''
    let message = ''
    if (this.props.notifications[0]) {
      type = this.props.notifications[0].type
      message = this.props.notifications[0].message
    }
    console.log(this.props.notifications)
    return (
      <Alert type={type}>
        {message &&
          <p>{message}</p>
        }
      </Alert>
    )
  }
}

const Alert = styled.div`
  position: fixed;
  z-index: 2020;
  min-height: ${props => props.type ? 60 : 0}px;
  transition: all 0.25s linear;
  background: #fff;
  width: 100vw;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: green;
  p {
    font-size: 16px;
    line-height: 60px;
    width: fit-content;
    margin: 0;
  }

  ${props => props.type === 'error' && css`
    color: red;
  `}
`

export default connect(
  state => ({
    notifications: Object.keys(state.notifications).map((key) => {
      return state.notifications[key];
    })
  }),
  dispatch => ({

  })
) (AlertBar)
