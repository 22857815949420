import {Cookies} from 'react-cookie'
const cookies = new Cookies()

export const setGolfer = (payload) => {
	return cookies.set(process.env.REACT_APP_GOLFER_COOKIE, payload, {path: '/'});
}

export const getGolfer = () => {
  return cookies.get(process.env.REACT_APP_GOLFER_COOKIE);
}

export const clearGolfer = () => {
	return cookies.remove(process.env.REACT_APP_GOLFER_COOKIE, {path: '/'});
}
