import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux'
import styled from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'
import theme from '../../styles/theme'
import {Flex, Box} from '../../styles/grid'
import {register} from '../../actions/auth'
import {success, error} from '../../actions/notifications'
import { Formik, Form, Field, ErrorMessage } from 'formik';
const inputStyle = {
  height: '45px',
  width: '100%',
  marginBottom: '35px',
  paddingLeft: '15px'
}

const validate = (values) => {
  let errors = {}
  if (!values.first_name) {
    errors.email = 'Required';
  } else if (!values.first_name) {
    errors.first_name = 'Required';
  } else if (!values.last_name) {
    errors.last_name = 'Required';
  }
  return errors;
}

class GolferForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialValues: {
        first_name: props.golfer.first_name,
        last_name: props.golfer.last_name,
        email: props.golfer.email,
        zipcode: props.golfer.zipcode,
        course_budget: props.golfer.course_budget
      }
    }
  }
  render() {
    const {history, golfer, patchGolfer, notifySuccess} = this.props;
    return (
      <FormContainer>
        <Formik
          initialValues={this.state.initialValues}
          validate={values => validate(values)}
          onSubmit={(values, { setSubmitting }) => {
            patchGolfer(golfer.id, values).then((response) => {
              notifySuccess('You have successfully updated your account.')
              setSubmitting(false)
            })
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Flex justifyContent={'space-between'} flexWrap={'wrap'}>
                {/* <Box width={'40%'}>
                </Box> */}
                <Box width={['100%', '50%']}>
                  <Label>First Name</Label>
                  <Field style={inputStyle} placeholder={'First Name'} type="text" name="first_name" />
                  <ErrorMessage name="first_name" component="div" />

                  <Label>Last Name</Label>
                  <Field style={inputStyle} placeholder={'Last Name'} type="text" name="last_name" />
                  <ErrorMessage name="last_name" component="div" />

                  {/* <Label>Handicap</Label>
                  <Field style={inputStyle} placeholder={'Handicap'} type="text" name="handicap" />
                  <ErrorMessage name="handicap" component="div" /> */}

                  <Label>Zip Code</Label>
                  <Field style={inputStyle} placeholder={'Zip Code'} type="text" name="zipcode" />
                  <ErrorMessage name="zipcode" component="div" />

                  <Label>Course Budget</Label>
                  <Field style={inputStyle} name="course_budget" component="select" placeholder="Course Budget">
                    <option value={50}>Up to $50</option>
                    <option value={80}>Up to $80</option>
                    <option value={100}>Up to $100</option>
                    <option value={150}>Up to $150</option>
                    <option value={500}>No budget</option>
                  </Field>

                  <SubmitButton type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      'Submit'
                    )}
                  </SubmitButton>
                </Box>
                <Flex overflow={'hidden'} justifyContent={'center'} alignItems={'center'} width={[0, '40%']}>
                  <WhoDat src="//lvg.s3.us-east-2.amazonaws.com/kott/whodatgolfer.png" />
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      </FormContainer>
    )
  }
}
const Label = styled.label`
  font-weight: bolder;
`

const WhoDat = styled.img`
  width: 80%;
  height: 80%;
`

const SubmitButton = styled.button`
  height: 50px;
  background: ${theme.colors.purple};
  color: #fff;
  font-size: 16px;
  font-weight: bolder;
  width: 100%;
  margin-top: 30px;
  border: none;
`

const FormContainer = styled.div`
  min-width: 400px;
  margin-top: 30px;

  form > input [type="text"] {
    width: 100%;
  }
`

export default connect(
  state => ({}),
  dispatch => ({
    register: bindActionCreators(register, dispatch),
    notifySuccess: bindActionCreators(success.bind(null, 'api_success'), dispatch),
    notifyError: bindActionCreators(error.bind(null, 'api_error'), dispatch)
  })
) (GolferForm)
