import React from 'react'
import {store} from '../../index'
import {logout} from '../../actions/auth'
import {getGolfer} from '../../utils/golfer'

function Logout(props) {
  if (getGolfer()) {
    store.dispatch(logout())
  }
  props.history.push('/auth')
  return (
    <div></div>
  )
}

export default Logout
