import React, {Component} from 'react';
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actions} from '../../actions/resources'
import {setTeeTimeModal, setScoreModal} from '../../actions/matchmaking'
import {getGolfer} from '../../utils/golfer'
import {Route} from 'react-router-dom'
import AccountHome from './account-home'
import Teams from './account-teams'
import Events from './account-events'
import AccountSidebar from './account-sidebar'
import {Box, Container} from '../../styles/grid'
import Alert from '../../components/common/Alert'
import TeeTimeModal from '../../components/common/tee-time-modal'
import ScoreModal from '../../components/common/add-score-modal'
import Header from '../../components/common/header'

class Account extends Component {
  state = {
    width: window.innerWidth,
    expanded: false
  }
  componentDidMount() {

    const {fetchGolfer} = this.props
    if (getGolfer()) {
      fetchGolfer(getGolfer().id)
    } else {
      this.props.history.push('/auth')
    }

    this.setState({
      isMobile: window.innerWidth <= 769
    })
    window.addEventListener('resize', () => {
      this.setState({
        width: window.innerWidth,
        isMobile: window.innerWidth <= 769
      })
    })
  }

  toggleExpand = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  _routes = () => {
    const {match} = this.props
    return [
      <Route exact path={match.url} render={() => <AccountHome {...this.props} />} />,
      <Route path={`${match.url}/teams`} render={() => <Teams {...this.props} />} />,
      <Route path={`${match.url}/events`} render={() => <Events {...this.props} />} />,
    ]
  }

  _openTeeTimeModal = () => {
    this.props.setTeeTimeModal(true)
  }

  openScoreModal = () => {
    this.props.setScoreModal(true)
  }

  render() {
    return [
      <Header {...this.props}/>,
      <Alert {...this.props}/>,
      <Container minHeight={window.innerHeight} display={'flex'} justifyContent={'space-between'}>
        <AccountSidebar
          _openTeeTimeModal={this._openTeeTimeModal}
          openScoreModal={this.openScoreModal}
          toggleExpand={this.toggleExpand}
          isMobile={this.state.isMobile}
          expanded={this.state.expanded}
          {...this.props} />
        <Box p={[20, 40]} width={['100%', '70%']}>
          {this.props.golfer && this._routes()}
        </Box>
        {/* <TeeTimeModal /> */}
        <ScoreModal />
      </Container>
    ]
  }
}

export default connect(
  state => ({
    golfer: state.resources.detail.golfer
  }),
  dispatch => ({
    fetchGolfer: bindActionCreators(actions.fetchGolfer, dispatch),
    createTeam: bindActionCreators(actions.createTeam, dispatch),
    patchGolfer: bindActionCreators(actions.patchGolfer, dispatch),
    setTeeTimeModal: bindActionCreators(setTeeTimeModal, dispatch),
    setScoreModal: bindActionCreators(setScoreModal, dispatch),
  })
)(Account)
