import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux'
import styled from 'styled-components'
import theme from '../../styles/theme'
import {register} from '../../actions/auth'
import Spinner from 'react-bootstrap/Spinner'
import { Formik, Form, Field, ErrorMessage } from 'formik';


class RegisterForm extends Component {
  render() {
    const {history, register, inline} = this.props;
    return (
      <FormContainer>
        {!inline &&
          <FormHeader>
            Join the Tour...of schleps
          </FormHeader>
        }
        <Formik
          initialValues={{ email: '', password: '' }}
          validate={values => {
            let errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            register(values).then((response) => {
              if (inline) {
                this.props.handleSubmit()
              } else {
                history.push('/account')
              }
              setSubmitting(false)
            })
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Inputs>
                <Field placeholder={'zip code'} type="text" name="zipcode" />
                <ErrorMessage name="zipcode" component="div" />
                <Field placeholder={'email'} type="email" name="email" />
                <ErrorMessage name="email" component="div" />
                <Field placeholder={'password'} type="password" name="password" />
                <ErrorMessage name="password" component="div" />
                <SubmitButton type="submit" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    'Submit'
                  )}
                </SubmitButton>
              </Inputs>
            </Form>
          )}
        </Formik>
        <RegisterLink>
          Already have an account?
          <button onClick={() => inline ? history.push('/auth') : this.props.showForm('login')}>
          Sign In.
        </button>
      </RegisterLink>
        {/* <Inputs>
          <input placeholder='first name' type='text' />
          <input placeholder='last name' type='text' />
          <input placeholder='email' type='email' />
          <input type='number' placeholder="handicap" />
          <input placeholder='password' type='password' />
        </Inputs> */}
      </FormContainer>
    )
  }
}

const SubmitButton = styled.button`
  height: 75px;
  background: ${theme.colors.purple};
  color: #fff;
  font-size: 18px;
  font-weight: bolder;
  width: 100%;
`

const RegisterLink = styled.span`
  display: block;
  margin-top: 30px;
  font-size: 16px;
  button {
    font-size: 16px;
    border: none;
    background: none;
    appearance: none;
    -webkit-appearance: none;
    color: ${theme.colors.blue};
  }
`

const FormHeader = styled.h3`
  font-size: 25px;
  margin: 20px 0;
`

const Inputs = styled.div`
  height: 410px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  input {
    padding-left: 17px;
    font-size: 22px;
    height: 75px;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
    color: ${theme.colors.darkGray};
  }
`

const FormContainer = styled.div`
  min-width: 400px;
  margin-top: 30px;
`

const InputPlacer = styled.div`

`

export default connect(
  state => ({}),
  dispatch => ({
    register: bindActionCreators(register, dispatch)
  })
) (RegisterForm)
