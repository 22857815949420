import React from 'react'
import styled from 'styled-components'
import Header from '../../components/common/header'
import theme from '../../styles/theme'
import {Container, Flex, Col, Box} from '../../styles/grid'
function AboutPage(props) {
  return [
    <Header {...props} />,
    <TopHero>
      <Container>
        <Col alignItems={'center'}>
          <Title src= "//lvg.s3.us-east-2.amazonaws.com/kott/logos/kott-clean.png" />
          <HeroHeader>
            Who says you have to be a professional to be on tour?
          </HeroHeader>
        </Col>
      </Container>
    </TopHero>,
    <AboutContent>
      <Flex display={'flex'} alignItems={'center'} flexDirection={'column'}>
        <Box width={[320, 500]} mt={40}>
          <ContentHeader>
            We started a tour that we would want to be in.
          </ContentHeader>
          <ContentText>
            With fun being the first thing in mind, and events and prizes to sweeten the deal.
          </ContentText>
        </Box>

        <Box width={[320, 500]} mt={40}>
          <ContentHeader>
            How does it work?
          </ContentHeader>
          <ContentText>
            Simple, choose a day you would like to play - and let us match you other golfers on the platform. We will match you
            up with golfers based on course budget and location. You can also set up games as a group and play with your friends!
          </ContentText>

          <ContentText>
            Once a round is completed, each scorecard will have to be verified by the players of the group, via the app or website.&nbsp;
            As you post more and more scores, you will accumulate points
          </ContentText>

          <ContentText>
            Your points will affect your standings in your area, and ultimately affect&nbsp;
            where you are seeded in our King of the Tee Hosted Majors and National Events!
          </ContentText>
        </Box>

        <Box width={[320, 500]} mt={40}>
          <ContentHeader>
            How much does it cost?
          </ContentHeader>
          <ContentText>
            You set your own budget for playing rounds throught the season, and we will match you up accordingly.&nbsp;
            Events will vary on price - depending on the course quality, time of year, and the magnitude of the event.
          </ContentText>
        </Box>

        <Box width={[320, 500]} mt={40}>
          <ContentHeader>
            Can anyone play in King of the Tee Hosted Events?
          </ContentHeader>
          <ContentText>
            Absof****lutely, the more the merrier. All events will be flighted by skill.
          </ContentText>
        </Box>

        <Box width={[320, 500]} mt={40}>
          <ContentHeader>
            How will you deal with sandbaggers?
          </ContentHeader>
          <ContentText>
            We will take sandbagging very seriously. All scores will need to be verified amongst the group.
            Golfer's flights will be based on the verified scores recorded on the platform.
          </ContentText>
        </Box>

        <FindEvents onClick={() => props.history.push('/auth')}>
          Create Account
        </FindEvents>
      </Flex>
    </AboutContent>
  ]
}

const FindEvents = styled.button`
  border: none;
  background: ${theme.colors.purple};
  color: #fff;
  height: 67px;
  width: 245px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 110px;
`

const ContentText = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 20px;
`

const ContentHeader = styled.h3`
  font-size: 30px;
  font-weight: 300;
  text-align: center;
`

const AboutContent = styled.div`
  padding: 40px 0 100px 0;
`

const Title = styled.img`
  margin: 0;
  padding: 0;
  width: 250px;
  line-height: 1.2;
  text-align: center;
  font-family: 'Cinzel', serif;
  @media (max-width: 760px) {
    width: 200px;
  }
`

const HeroHeader = styled.h2`
  font-size: 40px;
  text-align: center;
  line-height: 1.3;
  color: #fff;
  font-weight: bold;
  width: 660px;
  margin-top: 40px;
  @media (max-width: 760px) {
    width: 100%;
  }
`

const TopHero = styled.div`
  width: 100vw;
  min-height: 200px;
  padding: 80px 0;
  background: url(//lvg.s3.us-east-2.amazonaws.com/kott/kott-tee-off.jpg)no-repeat center center;
`

export default AboutPage
